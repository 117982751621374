import React, { useState } from "react";
import { Modal, ModalBody, Button, Label } from 'reactstrap'
import advertisementService from "services/api/advertisementService";

function AdvertisementSendModal({ advertisement, onClose, onSend }) {
  const [date, setDate] = useState(null);

  const handleSend = (id) => {
    console.log(date);
    advertisementService
      .send(id, date)
      .then(() => {
        setDate(null);
        onSend()
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Modal isOpen={!!advertisement} toggle={onClose}>
      {advertisement && (
        <ModalBody>
            <Label>Date</Label>
            {console.log(
              new Date().toISOString().replace(/:\d\d\..*/, '')
            )}
            <input
              className="d-block w-100 mb-4"
              type="datetime-local"
              defaultValue={
                new Date().toISOString().replace(/:\d\d\..*/, '')
              }
              onChange={(e) => setDate(new Date(e.target.value))}
            />
          <Button onClick={() => handleSend(advertisement.id)}>
            Send to all users
          </Button>
        </ModalBody>
      )}
    </Modal>
  );
}

export default AdvertisementSendModal;
