import apiAxiosInstance from "./apiAxiosInstance";

class UserService {
  constructor(client) {
    this.client = client;
  }

  async getAllUsers(search) {
    return this.client.get(`/users?search=${search}`).then((res) => res.data);
  }

  async getFullUser(telegram_id) {
    return this.client.get(`/users/${telegram_id}`).then((res) => res.data);
  }

  async cancelInvitation(telegram_id) {
    return this.client.post(`/users/${telegram_id}/cancel-invitation`);
  }

  async authorizeUser(telegram_id) {
    return this.client.post(`/users/${telegram_id}/authorize`, { authorize: true })
  }

  async deauthorizeUser(telegram_id) {
    return this.client.post(`/users/${telegram_id}/authorize`, { authorize: false })
  }

  async banUserInAllChats(telegram_id) {
    return this.client.post(`/users/${telegram_id}/ban-in-all-chats`, { ban: true })
  }

  async unbanUserInAllChats(telegram_id) {
    return this.client.post(`/users/${telegram_id}/ban-in-all-chats`, { ban: false })
  }

  async syncUser(telegram_id) {
    return this.client.get(`/users/${telegram_id}/sync`)
  }

  async dropUnauthorizedMessageCount(telegram_id) {
    return this.client.post(`/users/${telegram_id}/drop-unauthorized-message-count`)
  }

}

const userService = new UserService(apiAxiosInstance);

export default userService;
