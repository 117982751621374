import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
} from "reactstrap";
import chatService from "services/api/chatService";

function Chats() {
  const queryClient = useQueryClient()
  const {
    status,
    error,
    data: chats,
  } = useQuery(["chats"], { queryFn: () => chatService.getAllChats() });

  const handleDelete = (chatId) => {
    chatService.deleteChat(chatId)
    .then(() => {
      queryClient.invalidateQueries(['chats']);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  const handleSync = (chatId) => {
    chatService.syncChat(chatId)
    .then(() => {
      queryClient.invalidateQueries(['chats']);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  return (
    <>
      <div className="content">
        <h1 className="mb-4">
          Chats
        </h1>
        <Row>
          <Col xs={12} className="mb-4">
            <Link to="/admin/chats/create">
              <Button>Create chat</Button>
            </Link>
          </Col>
          <Col xs={12}>
            {
              {
                loading: () => <div>Loading...</div>,
                error: () => <div>{error?.message}</div>,
                success: () => (
                  <Row xs={1}>
                    {chats.map((chat) => (
                      <Col key={chat.id}>
                        <Card>
                          <CardBody>
                            <div className="mb-5">
                              <div className="text-white" style={{ fontSize: '16px' }}>
                                {chat.title} <span style={{ color: '#ffffff40' }}>({chat.id})</span>
                              </div>
                              {chat.description && (
                                <div className="mt-2" style={{ color: '#ffffff40', fontSize: '16px' }}>
                                  {chat.description}
                                </div>
                              )}
                            </div>
                            <Row xs={2} md={2} lg={4}>
                              <Col>
                                <Button className="w-100" onClick={() => handleSync(chat.id)}>Sync</Button>
                              </Col>
                              <Col>
                                <Link
                                  to={`/admin/chats/${chat.id}/edit`}
                                  className="mr-4"
                                >
                                  <Button className="w-100">Edit</Button>
                                </Link>
                              </Col>
                              <Col>
                                <Link
                                  to={`/admin/chats/${chat.id}/users`}
                                  className="mr-4"
                                >
                                  <Button className="w-100">Manage users</Button>
                                </Link>
                              </Col>
                              <Col>
                                <Button
                                  className="px-3 d-flex w-100 justify-content-center"
                                  color="danger"
                                  onClick={() => handleDelete(chat.id)}
                                >
                                  <i className="tim-icons icon-trash-simple mr-2" />
                                  <span>Delete</span>
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ),
              }[status]()
            }
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Chats;
