import apiAxiosInstance from "./apiAxiosInstance";

class EventService {
  constructor(client) {
    this.client = client;
  }

  async getActual() {
    return this.client.get('/events').then((res) => res.data)
  }

  async getFuture() {
    return this.client.get(`/events/future`).then((res) => res.data)
  }

  async getFailed() {
    return this.client.get(`/events/failed`).then((res) => res.data)
  }

  async delete(id) {
    return this.client.delete(`/events/${id}`)
  }

  async retry(id) {
    return this.client.get(`/events/${id}/retry`).then((res) => res.data)
  }
}

const eventService = new EventService(apiAxiosInstance);

export default eventService;
