import apiAxiosInstance from "./apiAxiosInstance";

class AdvertisementService {
  constructor(client) {
    this.client = client;
  }

  async getAll() {
    return this.client.get('/advertisements').then((res) => res.data)
  }

  async getOne(id) {
    return this.client.get(`/advertisements/${id}`).then((res) => res.data)
  }

  async create(data) {
    return this.client.post('/advertisements', data)
  }

  async update(id, data) {
    return this.client.put(`/advertisements/${id}`, data);
  }

  async delete(id) {
    return this.client.delete(`/advertisements/${id}`)
  }

  async send(id, date) {
    const dateWithoutTimezone = !date ? undefined : new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
    return this.client.post(`/advertisements/${id}/send`, { date: dateWithoutTimezone })
  }

  async cancel(id) {
    return this.client.post(`/advertisements/${id}/cancel`)
  }

}

const advertisementService = new AdvertisementService(apiAxiosInstance);

export default advertisementService;
