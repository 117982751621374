import UserChatList from "components/UserChatList/UserChatList";
import React, { useCallback } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row, Button } from "reactstrap";
import chatService from "services/api/chatService";
import userService from "services/api/userService";

function User() {
  const { id: telegram_id } = useParams();

  const {
    status,
    data: user,
    error,
    refetch,
  } = useQuery({
    queryKey: ["user", telegram_id],
    queryFn: () => userService.getFullUser(telegram_id),
  });

  const handleAuthorize = useCallback(() => {
    userService
      .authorizeUser(telegram_id)
      .then(() => {
        refetch()
      })
      .catch((error) => {
        console.log(error)
      })
  }, [refetch, telegram_id]);

  const handleDeauthorize = useCallback(() => {
    userService
      .deauthorizeUser(telegram_id)
      .then(() => {
        refetch()
      })
      .catch((error) => {
        console.log(error)
      })
  }, [telegram_id, refetch]);

  const handleCancelInvitation = useCallback(() => {
    userService
      .cancelInvitation(telegram_id)
      .then(() => {
        refetch()
      })
      .catch((error) => {
        console.log(error)
      })
  }, [telegram_id, refetch]);

  const handleAddBannedChat = useCallback(
    (chat_id) => {
      chatService
        .banUser(chat_id, telegram_id)
        .then(() => {
          refetch();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [telegram_id, refetch]
  );

  const handleDeleteBannedChat = useCallback(
    (chat_id) => {
      chatService
        .unbanUser(chat_id, telegram_id)
        .then(() => {
          refetch();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [telegram_id, refetch]
  );

  const handleBanInAllChats = useCallback(
    () => {
      userService
        .banUserInAllChats(telegram_id)
        .then(() => {
          refetch();
        })
        .catch((error) => {
          console.log(error);
        });
    }, [telegram_id, refetch]
  )

  const handleUnbanInAllChats = useCallback(
    () => {
      userService
        .unbanUserInAllChats(telegram_id)
        .then(() => {
          refetch();
        })
        .catch((error) => {
          console.log(error);
        });
    }, [telegram_id, refetch]
  )

  const handleSync = useCallback(
    () => {
      userService
        .syncUser(telegram_id)
        .then(() => {
          refetch();
        })
        .catch((error) => {
          console.log(error);
        })
    }, [telegram_id, refetch]
  )

  const handleDropUnauthorizedMessageCount = useCallback(
    () => {
      userService
        .dropUnauthorizedMessageCount(telegram_id)
        .then(() => {
          refetch()
        })
        .catch((error) => {
          console.log(error)
        })
    }, [telegram_id, refetch]
  )

  return (
    <div className="content">
      <h2 className="mb-4">
        {{
          loading: () => <div>Loadin...</div>,
          error: () => <div>Error loading user with id {telegram_id}</div>,
          success: () => (
            <div className="d-flex justify-content-between align-items-start">
              <div>
                <div>
                  ID: {user.telegram_id}
                </div>
                <div>
                  {user.first_name} {user.last_name} {user.username && `(@${user.username})`}
                </div>
              </div>
              <Button onClick={handleSync}>Sync</Button>
            </div>
          )
        }[status]()}
      </h2>
      <div>
        {{
          loading: () => <div>Loading...</div>,
          error: () => <div>{error.message}</div>,
          success: () => (
            <div>
              <Row xs={1}>
                <Col>
                  <Card>
                    <CardBody style={{ fontSize: "16px" }}>
                      <div>
                        {user.is_authed ? (
                          <>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ gap: "5px" }}
                            >
                              <span>
                                User is{" "}
                                <span className="text-success">authorized</span>
                              </span>
                              <Button
                                color="danger"
                                onClick={handleDeauthorize}
                              >
                                Deauthorize
                              </Button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="d-flex align-items-center  justify-content-between"
                              style={{ gap: "5px" }}
                            >
                              <span>
                                User is{" "}
                                <span className="text-danger">
                                  unauthorized
                                </span>
                              </span>
                              <Button color="success" onClick={handleAuthorize}>
                                Authorize
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <CardBody className="d-flex align-items-center justify-content-between">
                      Unauthorized message count: {user.unauthorized_message_count}
                      <Button onClick={handleDropUnauthorizedMessageCount} disabled={user.unauthorized_message_count === 0}>
                        Drop
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
                {user.invitedChat && (
                  <Col>
                    <Card>
                      <CardBody className="d-flex align-items-center justify-content-between">
                        <div style={{ fontSize: '16px', color: 'white' }}>
                          <div>
                            User have been invited to resolve capcha from chat:{" "}
                          </div>
                          <div>
                            {user.invitedChat.title}{' '}
                            <span style={{ color: '#ffffff40' }}>
                              {user.invitedChat.id}
                            </span>
                          </div>
                        </div>
                        <Button color="danger" onClick={handleCancelInvitation}>
                          Cancel invitation
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                )}
                <Col>
                  <Row xs={1}>
                    <Col className="mb-4">
                      <div className="d-flex" style={{ gap: '20px' }}>
                        <Button color="danger" onClick={handleBanInAllChats}>
                          Ban in all chats
                        </Button>
                        <Button color="success" onClick={handleUnbanInAllChats}>
                          Unban in all chats
                        </Button>
                      </div>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <CardHeader className="text-center" tag="h4">
                          Banned chats
                        </CardHeader>
                        <CardBody>
                          <UserChatList
                            chats={user.bannedChats}
                            onAdd={handleAddBannedChat}
                            onDelete={handleDeleteBannedChat}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          ),
        }[status]()}
      </div>
    </div>
  );
}

export default User;
