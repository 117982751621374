import LoginForm from "components/LoginForm/LoginForm";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { refreshAuthThunk } from "store/slices/auth/thunks";

function Protected() {
  const status = useSelector((state) => state.auth.user.status);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshAuthThunk())
      .unwrap()
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="wrapper">
      {
        {
          unknown: (
            <div className="full-size-center">
              Authorizing...
            </div>
          ),
          guest: (
            <div className="full-size-center">
              <LoginForm />
            </div>
          ),
          logged: <Outlet />,
        }[status]
      }
    </div>
  );
}

export default Protected;
