import React from 'react';
import { Card, CardBody } from 'reactstrap';

function FileUploader({ className, style, files, setFiles }) {

  const handleAdd = (newFiles) => {
    setFiles((prev) => {
      const newFilesFiltered = newFiles.filter(
        (newFile) => !prev.find((prevFile) => prevFile.name === newFile.name)
      )
      return [...prev, ...newFilesFiltered]
    })
  }

  const handleRemove = (i) => {
    setFiles((prev) => prev.filter((_, index) => index !== i))
  }

  return (
    <div className={className} style={{ maxWidth: '300px', ...style }}>
      <div
        className='rounded mb-2 position-relative'
        style={{ height: '150px', border: '2px dashed #ffffff50' }}
      >
        <div
          className='position-absolute text-center'
          style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
        >
          Upload files
        </div>
        <input
          key={files.length} // remount each time file count chages
          className="file-uploader-input w-100 h-100"
          multiple
          type='file'
          onChange={(e) => {
            handleAdd(Array.from(e.target.files))
          }}
        />
      </div>
      <div className='d-flex flex-column' style={{ gap: '10px' }}>
        {files.map((file, i) => (
          <Card key={file.name} className='mb-0'>
            <CardBody className='d-flex justify-content-between align-items-center'>
              <div
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: '100%',
                  flex: '1 1 0%'
                }}
                title={file.name}
              >
                  {file.name}
              </div>
              <i
                className='tim-icons icon-trash-simple icon-button'
                style={{ color: 'red', flexShrink: 0 }}
                onClick={() => handleRemove(i)}
              />
            </CardBody>
          </Card>
        ))} 
      </div>
    </div>
  )
}

export default FileUploader