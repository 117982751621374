import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { loginThunk } from "store/slices/auth/thunks";

function LoginForm() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginThunk({ login, password }))
      .unwrap()
      .catch((error) => {
        console.log(error.response.data.message);
        setError(error.response.data.message);
      });
  };

  return (
    <Form onSubmit={handleSubmit} className="position-relative" style={{ width: '300px' }}>
      <FormGroup>
        <Label for="login">Login</Label>
        <Input
          id="login"
          name="login"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          placeholder="Enter login"
          type="text"
        />
      </FormGroup>
      <FormGroup>
        <Label for="password">Password</Label>
        <Input
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
      </FormGroup>
      <div className="text-center">
        <Button>Login</Button>
      </div>
      {error && 
        <Alert
          className="position-absolute top-100 mt-2"
          color="danger"
          toggle={() => setError(null)}
        >
          {error}
        </Alert>
      }
    </Form>
  );
}

export default LoginForm;
