import authAxiosInstance from './authAxiosInstance';

class AuthService {
  constructor(client) {
    this.client = client
  }

  async login(formData) {
    const res = await this.client.post('/auth/login', formData);
    return res.data;
  }

  async getUser() {
    const res = await this.client.get('/auth/get-user');
    return res.data;
  }

  async refresh() {
    const res = await this.client.get('/auth/refresh');
    return res.data;
  }

  async logout() {
    return this.client('/auth/logout');
  }
}

const authService = new AuthService(authAxiosInstance);

export default authService;
