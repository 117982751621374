import apiAxiosInstance from './apiAxiosInstance';

class ChatService {
  constructor(client) {
    this.client = client
  }

  async getAllChats() {
    return this.client.get('/chats').then((res) => res.data);
  }

  async getChatBans(chatId, search) {
    return this.client.get(`/chats/${chatId}/bans?search=${search}`).then((res) => res.data);
  }

  async unbanUser(chatId, telegramId) {
    return this.client.post(`/chats/${chatId}/ban`, { telegram_id: telegramId, ban: false })
  }

  async banUser(chatId, telegramId) {
    return this.client.post(`/chats/${chatId}/ban`, { telegram_id: telegramId, ban: true })
  }

  async authorizeUser(chatId, telegramId) {
    return this.client.post(`/chats/${chatId}/authorize`, { telegram_id: telegramId, authorize: true })
  }

  async deauthorizeUser(chatId, telegramId) {
    return this.client.post(`/chats/${chatId}/authorize`, { telegram_id: telegramId, authorize: false })
  }

  async inviteUser(chatId, telegramId) {
    return this.client.post(`/chats/${chatId}/invite`, { telegram_id: telegramId, invite: true })
  }

  async deinviteUser(chatId, telegramId) {
    return this.client.post(`/chats/${chatId}/invite`, { telegram_id: telegramId, invite: false })
  }

  async getChatAuthUsers(chatId, search) {
    return this.client.get(`/chats/${chatId}/auth-users?search=${search}`).then((res) => res.data)
  }

  async getChatBannedUsers(chatId, search) {
    return this.client.get(`/chats/${chatId}/banned-users?search=${search}`).then((res) => res.data)
  }

  async getChatInvitedUsers(chatId, search) {
    return this.client.get(`/chats/${chatId}/invited-users?search=${search}`).then((res) => res.data)
  }

  async getChat(chatId) {
    return this.client.get(`/chats/${chatId}`).then((res) => res.data);
  }

  async createChat(data) {
    return this.client.post('/chats', data)
  }

  async updateChat(id, data) {
    return this.client.patch(`/chats/${id}`, data)
  }

  async deleteChat(id) {
    return this.client.delete(`/chats/${id}`);
  }

  async syncChat(id) {
    return this.client.get(`/chats/${id}/sync`);
  }

}

const chatService = new ChatService(apiAxiosInstance);

export default chatService;
