import FileUploader from "components/FileUploader/FileUploader";
import React, { useState } from "react";
import { Input, Button, Card, CardBody } from "reactstrap";
import advertisementService from "services/api/advertisementService";

function AdvertisementForm({ advertisement, onSave }) {
  const [text, setText] = useState(advertisement?.text ?? "");
  const [existedFiles, setExistedFiles] = useState(advertisement?.files);
  const [files, setFiles] = useState([]);

  const handleRemoveExistedFile = (index) => {
    setExistedFiles((prev) => prev.filter((_, i) => index !== i));
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append("text", text);
    for (const file of files) {
      formData.append("files", file);
    }
    if (advertisement) {
      //update
      const removedFiles = advertisement.files.filter(
        (file) =>
          !existedFiles.find((existedFile) => existedFile.path === file.path)
      );

      formData.append('removedFiles', JSON.stringify(removedFiles))
      
      advertisementService
        .update(advertisement.id, formData)
        .then(() => {
          onSave();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      //create
      advertisementService
        .create(formData)
        .then(() => {
          onSave();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="content">
      <h1 className="mb-4">
        {advertisement ? (
          <>Advertisement with id {advertisement.id}</>
        ) : (
          <>Advertisement creation</>
        )}
      </h1>
      <div>
        <Input
          type="textarea"
          rows={6}
          style={{
            padding: "10px",
            marginBottom: "10px",
            fontSize: "16px",
            maxHeight: "unset",
            resize: "vertical",
          }}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        {existedFiles?.length > 0 && (
          <>
            <div className="mt-3">
              Already attached files
            </div>
            <div className="d-flex flex-column" style={{ gap: '10px' }}>
              {existedFiles.map((file, i) => (
                <Card
                  key={file.path}
                  className="m-0"
                >
                  <CardBody
                    className="d-flex justify-content-between align-items-center text-white py-2"
                  >
                    <div>{file.path}</div>
                    <i
                      className="tim-icons icon-trash-simple icon-button"
                      style={{ color: "red" }}
                      onClick={() => handleRemoveExistedFile(i)}
                    />
                  </CardBody>
                </Card>
              ))}
            </div>
          </>
        )}
        <FileUploader files={files} setFiles={setFiles} className="mt-4" />
        <Button className="mt-4" onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default AdvertisementForm;
