import axios from 'axios';
import authService from '../auth/authService';
import { setAccessToken } from 'store/slices/auth/slice';

// accessToken
const apiAxiosInstance = axios.create({
  baseURL: '/api',
});

// this thing here is required to resolve dependency cycle
let store;
export const injectStore = (_store) => {
  store = _store;
};

// Перехватчик запроса
apiAxiosInstance.interceptors.request.use((config) => {
  if (!config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${store?.getState().auth.accessToken}`;
  }
  return config;
});

// Перехватчик ответа
apiAxiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const prevRequest = err.config; // необходимо чтобы понять что это второй запрос
    if (err.response?.status === 403 && !prevRequest.sent) {
      prevRequest.sent = true;
      const { accessToken } = await authService.refresh();
      store.dispatch(setAccessToken(accessToken));
      prevRequest.headers.Authorization = `Bearer ${accessToken}`;
      return apiAxiosInstance(prevRequest);
    }
    return Promise.reject(err);
  },
);

export default apiAxiosInstance;