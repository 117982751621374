import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from "react-query";
import advertisementService from '../services/api/advertisementService'
import AdvertisementForm from 'components/AdvertisementForm/AdvertisementForm';

function Advertisement() {
  const { id } = useParams();

  const navigate = useNavigate();

  const { status, data, error } = useQuery({
    queryKey: ['advertisement', id],
    queryFn: () => advertisementService.getOne(id)
  })

  return (
    <div className='content'>
      <div>
        {{
          loading: () => <div>Loading...</div>,
          error: () => <div>{error.message}</div>,
          success: () => (
            <AdvertisementForm
              key={JSON.stringify(data)}
              advertisement={data}
              onSave={() => navigate('/admin/advertisements')}
            />
          )
        }[status]()}
      </div>
    </div>
  )
}

export default Advertisement