import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, Route, Routes } from "react-router-dom";
import { Card, CardBody, Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import userService from "services/api/userService";
import useDebounce from "utils/useDebounce";

function Users() {
  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 500)

  const {
    status,
    data,
    error,
  } = useQuery(["users", searchDebounced], {
    queryFn: () => userService.getAllUsers(searchDebounced),
  });

  const [selectedUser, setSelectedUser] = useState(null);

  // update selectedUser when users changed
  useEffect(() => {
    if (selectedUser && data) {
      const found = data.users.find(
        (item) => item.telegram_id === selectedUser.telegram_id
      );
      if (found) {
        setSelectedUser(found);
      }
    }
  }, [data]);

  return (
    <>
      <div className="content">
        <h1 className="mb-4">
          Users
        </h1>
        <Row>
          <Col xs={12} className="mb-4">
            <InputGroup className="float-right m-0">
              <Input
                placeholder="Search for users"
                style={{ fontSize: '16px' }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search.length !== 0 && (
                <InputGroupText style={{ cursor: 'pointer' }} onClick={() => setSearch('')}>
                  <i className="tim-icons icon-simple-remove" />
                </InputGroupText>
              )}
              <InputGroupText>
                <i className="tim-icons icon-zoom-split" />
              </InputGroupText>
            </InputGroup>
          </Col>
          <Col xs={12}>
            {
              {
                loading: () => <div>Loading...</div>,
                error: () => <pre>{JSON.stringify(error, null, 2)}</pre>,
                success: () => (
                  <Row>
                    {data.users.map((user) => (
                      <Col
                        key={user.telegram_id}
                        xs={12}
                        className="mb-2"
                        style={{ color: "white" }}
                      >
                        <Link to={`/admin/users/${user.telegram_id}`}>
                          <Card
                            className="user-list-item m-0 text-white"
                          >
                            <CardBody>
                              <div>
                                ID: {user.telegram_id}
                              </div>
                              <div>
                                {user.first_name} {user.last_name} {user.username ? `@${user.username}` : ''}
                              </div>
                              <div>Created At: {user.created_at}</div>
                            </CardBody>
                          </Card>
                        </Link>
                      </Col>
                    ))}
                    {data.users.length !== data.total && (
                      <Col xs={12} className="text-center">
                        shown first {data.users.length} users of {data.total}.
                        Use search to get other users. 
                      </Col>
                    )}
                  </Row>
                ),
              }[status]()
            }
          </Col>
          {}
        </Row>
      </div>
      <Routes>
        <Route path="/:id" element={<div>hole</div>} />
      </Routes>
    </>
  );
}

export default Users;
