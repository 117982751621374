import { createSlice } from '@reduxjs/toolkit';
import { loginThunk, logoutThunk, refreshAuthThunk } from './thunks';

const initialState = {
  accessToken: '',
  user: {
    status: 'unknown',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.fulfilled, (_state, action) => action.payload);
    builder.addCase(logoutThunk.fulfilled, (_state, action) => action.payload);
    builder.addCase(refreshAuthThunk.fulfilled, (_state, action) => action.payload);
    builder.addCase(refreshAuthThunk.rejected, (state) => {
      state.user.status = 'guest';
    });
  },
});

export const { setAccessToken } = authSlice.actions;

export const selectUser = (store) => store.auth.user
export const selectUserStatus = (store) => store.auth.user.status;

export default authSlice.reducer;
