import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import chatService from "services/api/chatService";

function ChatForm({ chat }) {
  const isCreating = chat === undefined;
  const navigate = useNavigate();
  const [input, setInput] = useState({
    id: "",
    chat_message: '',
    bot_message: '',
    correct_answer: '',
    on_correct: '',
    on_incorrect: '',
    on_start_answer: '',
    deleteTimeMs: 30,
    ...chat,
  });

  const handleChange = (e) => {
    setInput((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isCreating) {
      chatService.createChat(input)
      .then(() => {
        navigate('/admin/chats')
      })
      .catch((error) => {
        console.log(error);
      })
    } else {
      chatService.updateChat(chat.id, input)
      .then(() => {
        navigate('/admin/chats');
      })
      .catch((error) => {
        console.log(error);
      })
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <h1>
          {isCreating ? (
            "Create new chat"
            ) : (
              <>
                <div>
                  {chat.title} <span style={{ color: "#ffffff40" }}>({chat.id})</span>
                </div>
                {chat.description && (
                  <div className="mt-4" style={{ color: '#ffffff40', fontSize: '20px' }}>
                    {chat.description}
                  </div>
                )}
              </>
          )}
        </h1>
      </Col>
      <Col xs={12} className="mt-2">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} className="mb-4">
              <FormGroup>
                <Label for="id">Chat ID</Label>
                <Input
                  type="text"
                  id="id"
                  name="id"
                  value={input.id}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} className="mb-4">
              <FormGroup>
                <Label for="chat_message">Message in chat</Label>
                <Input
                  type="textarea"
                  rows={6}
                  id="chat_message"
                  name="chat_message"
                  value={input.chat_message}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} className="mb-4">
              <FormGroup>
                <Label for="bot_message">Post text</Label>
                <Input
                  type="textarea"
                  style={{ maxHeight: "unset", resize: "vertical" }}
                  rows={6}
                  id="bot_message"
                  name="bot_message"
                  value={input.bot_message}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} className="mb-4">
              <FormGroup>
                <Label for="correct_answer">Correct answer</Label>
                <Input
                  type="text"
                  id="correct_answer"
                  name="correct_answer"
                  value={input.correct_answer}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} className="mb-4">
              <FormGroup>
                <Label for="on_correct">
                  Message to send when answer is correct
                </Label>
                <Input
                  type="textarea"
                  style={{ maxHeight: "unset", resize: "vertical" }}
                  rows={6}
                  id="on_correct"
                  name="on_correct"
                  value={input.on_correct}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} className="mb-4">
              <FormGroup>
                <Label for="on_incorrect">
                  Message to send when answer is incorrect
                </Label>
                <Input
                  type="textarea"
                  style={{ maxHeight: "unset", resize: "vertical" }}
                  rows={6}
                  id="on_incorrect"
                  name="on_incorrect"
                  value={input.on_incorrect}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} className="mb-4">
              <FormGroup>
                <Label for="on_start_answer">
                  Message to send at /start without capcha
                </Label>
                <Input
                  type="textarea"
                  style={{ maxHeight: "unset", resize: "vertical" }}
                  rows={6}
                  id="on_start_answer"
                  name="on_start_answer"
                  value={input.on_start_answer}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} className="mb-4">
              <FormGroup>
                <Label for="deleteTimeMs">
                  Delay before delete message
                </Label>
                <Input
                  type="number"
                  id="deleteTimeMs"
                  name="deleteTimeMs"
                  value={input.deleteTimeMs}
                  onChange={(e) => setInput((prev) => ({
                    ...prev,
                    deleteTimeMs: e.target.valueAsNumber
                  }))}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <Button type="submit">
                {isCreating ? "Create" : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

export default ChatForm;
