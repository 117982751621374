import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import chatService from "services/api/chatService";

function ChatSelector({ excludeIds = [], value, setValue }) {
  const {
    status,
    data: chats,
    error,
  } = useQuery(["chats"], {
    queryFn: () => chatService.getAllChats(),
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const filteredChats = useMemo(() => {
    if (!chats) return [];
    return chats.filter((chat) => !excludeIds.find((id) => id === chat.id));
  }, [chats, excludeIds]);

  return (
    <Dropdown
      className="flex-grow-1 mb-0"
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen((prev) => !prev)}
    >
      <DropdownToggle
        data-toggle="dropdown"
        tag="span"
        className="d-flex align-items-center justify-content-between px-2 rounded-lg"
        style={{
          border: "1px solid #e5e5e5",
          cursor: "pointer",
          gap: "5px",
          padding: "6px",
          color: value ? 'white' : undefined
        }}
      >
        {value ? (
          <>
            {(() => {
              const chat = chats.find((chat) => chat.id === value);
              return (
                <div>
                  {chat.title} {chat.id}
                </div>
              )
            })()}
          </>
        ) : (
          <div>
            Select Chat ID
          </div>
        )}
        <i className="tim-icons icon-minimal-down" />
      </DropdownToggle>
      <DropdownMenu>
        {filteredChats.length === 0 && (
          <DropdownItem text>No Items</DropdownItem>
        )}
        {
          {
            loading: <div>Loadign...</div>,
            error: <pre>{JSON.stringify(error, null, 2)}</pre>,
            success: filteredChats.map((chat) => (
              <DropdownItem key={chat.id} onClick={() => setValue(chat.id)}>
                <span style={{ color: 'black' }}>{chat.title}</span>{' '}
                <span style={{ color: '#000000a0' }}>{chat.id}</span>
              </DropdownItem>
            )),
          }[status]
        }
      </DropdownMenu>
    </Dropdown>
  );
}

export default ChatSelector;
