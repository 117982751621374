import { createAsyncThunk } from "@reduxjs/toolkit";
import authService from "services/auth/authService";

export const loginThunk = createAsyncThunk(
  "auth/loginThunk",
  (formData, thunkApi) =>
    authService
      .login(formData)
      .then((backendUser) => ({
        ...backendUser,
        user: { ...backendUser.user, status: "logged" },
      }))
      .catch((error) => thunkApi.rejectWithValue(error))
);

export const logoutThunk = createAsyncThunk("auth/logoutThunk", (_, thunkApi) =>
  authService
    .logout()
    .then(() => ({ accessToken: "", user: { status: "guest" } }))
    .catch((error) => thunkApi.rejectWithValue(error))
);

// export const getUserThunk = createAsyncThunk(
//   "auth/getUserThunk",
//   (_, thunkApi) =>
//     authService
//       .getUser()
//       .then((backendUser) => ({
//         ...backendUser,
//         user: { ...backendUser.user, status: "logged" },
//       }))
//       .catch((error) => thunkApi.rejectWithValue(error))
// );

export const refreshAuthThunk = createAsyncThunk(
  "auth/refreshAuthThunk",
  (_, thunkApi) =>
    authService
      .refresh()
      .then((backendUser) => ({
        ...backendUser,
        user: { ...backendUser.user, status: "logged" },
      }))
      .catch((error) => thunkApi.rejectWithValue(error))
);
