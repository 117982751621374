import ChatUserList from "components/ChatUserList/ChatUserList";
import Searchbar from "components/Searchbar/Searchbar";
import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import chatService from "services/api/chatService";
import useDebounce from "utils/useDebounce";

function ChatUsers() {
  const { id } = useParams();

  const chatQuery = useQuery({
    queryKey: ['chat', id],
    queryFn: () => chatService.getChat(id)
  })

  const [bannedUsersSearch, setBannedUsersSearch] = useState("");
  const bannedUsersSearchDebounced = useDebounce(bannedUsersSearch, 500);

  const [invitedUsersSearch, setInvitedUsersSearch] = useState("");
  const invitedUsersSearchDebounced = useDebounce(invitedUsersSearch, 500);

  const bannedUsersQuery = useQuery({
    queryKey: ["chat-banned-users", bannedUsersSearchDebounced],
    queryFn: () =>
      chatService.getChatBannedUsers(id, bannedUsersSearchDebounced),
  });

  const invitedUsersQuery = useQuery({
    queryKey: ["chat-invited-users", invitedUsersSearchDebounced],
    queryFn: () =>
      chatService.getChatInvitedUsers(id, invitedUsersSearchDebounced),
  });

  const handleDeleteBannedUser = useCallback(
    (telegram_id) => {
      chatService
        .unbanUser(id, telegram_id)
        .then(() => {
          bannedUsersQuery.refetch();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [id]
  );

  const handleDeleteInvitedUser = useCallback(
    (telegram_id) => {
      chatService
        .deinviteUser(telegram_id)
        .then(() => {
          invitedUsersQuery.refetch();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [id]
  );

  return (
    <div className="content">
      <Row xs={1}>
        {{
          loading: () => <div>Loading Title...</div>,
          error: () => <div className="text-danger">Cannot get title</div>,
          success: () => (
            <>
              <Col className="mb-4">
                <h1 className="m-0">
                  {chatQuery.data.title} <span style={{ color: "#ffffff30" }}>({id})</span>
                </h1>
              </Col>
              {chatQuery.data.description && (
                <Col className="mb-4" style={{ fontSize: '24px' }}>
                  {chatQuery.data.description}
                </Col>
              )}
            </>
          )
        }[chatQuery.status]()}
        <Col>
          <Card className="mb-4">
            <CardHeader className="text-center" tag="h4">
              Banned users
            </CardHeader>
            <CardBody>
              <div className="mb-2">
                <Searchbar
                  value={bannedUsersSearch}
                  setValue={setBannedUsersSearch}
                />
              </div>
              {{
                loading: () => <div>Loading...</div>,
                error: () => <div>{bannedUsersQuery.error.message}</div>,
                success: () => (
                  <ChatUserList
                    users={bannedUsersQuery.data.users}
                    total={bannedUsersQuery.data.total}
                    onDelete={handleDeleteBannedUser}
                  />
                ),
              }[bannedUsersQuery.status]()}
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="mb-4">
            <CardHeader className="text-center" tag="h4">
              Invited users
            </CardHeader>
            <CardBody>
              <div className="mb-2">
                <Searchbar
                  value={invitedUsersSearch}
                  setValue={setInvitedUsersSearch}
                />
              </div>
              {{
                loading: () => <div>Loading...</div>,
                error: () => <div>{invitedUsersQuery.error.message}</div>,
                success: () => (
                  <ChatUserList
                    users={invitedUsersQuery.data.users}
                    total={invitedUsersQuery.data.total}
                    onDelete={handleDeleteInvitedUser}
                  />
                ),
              }[invitedUsersQuery.status]()}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ChatUsers;
