import AdvertisementForm from 'components/AdvertisementForm/AdvertisementForm'
import { useNavigate } from 'react-router-dom'
import React from 'react'

function AdvertisementCreate() {
  const navigate = useNavigate();

  return (
    <AdvertisementForm onSave={() => navigate("/admin/advertisements")} />
  )
}

export default AdvertisementCreate