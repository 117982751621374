import React from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";

function Searchbar({ value, setValue }) {
  return (
    <InputGroup className="mb-4">
      <Input value={value} onChange={(e) => setValue(e.target.value)} />
      {value && (
        <InputGroupText
          style={{ cursor: "pointer" }}
          onClick={() => setValue("")}
        >
          <i className="tim-icons icon-simple-remove" />
        </InputGroupText>
      )}
      <InputGroupText>
        <i className="tim-icons icon-zoom-split" />
      </InputGroupText>
    </InputGroup>
  );
}

export default Searchbar;
