import ChatForm from "components/ChatForm/ChatForm";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import chatService from "services/api/chatService";

function ChatEdit() {
  const { chatId } = useParams();

  const { status, data: chat, error } = useQuery({
    queryKey: ['chat', chatId],
    queryFn: () => chatService.getChat(chatId)
  })

  return (
    <>
      <div className="content">
        <Row>
          <Col xs={12}>
            {{
              loading: () => <div>Loading...</div>,
              error: () => <div>{error.message}</div>,
              success: () => (
                <ChatForm chat={chat} />
              )
            }[status]()}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ChatEdit;
