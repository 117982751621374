import React from "react";
import eventService from "services/api/eventService";
import { Row, Col, Button, Card, CardHeader, CardBody } from "reactstrap";
import { useQuery } from "react-query";

function EventItem({ event, onRetry, onDelete }) {
  return (
    <Card>
      <CardHeader className="d-flex justify-content-between">
        <div>{event.id}</div>
        {event.failed_at && <div style={{ color: "red" }}>FAILED</div>}
      </CardHeader>
      <CardBody>
        <div>{event.date}</div>
        <div>{event.command_name}</div>
        <div>{event.payload}</div>
        <div className="d-flex float-right mt-2" style={{ gap: '10px' }}>
          {event.failed_at && (
            <Button onClick={() => onRetry(event.id)}>Retry</Button>
          )}
          <div>
            <Button color="danger" onClick={() => onDelete(event.id)}>
              Delete
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

function Events() {
  const failedEventsQuery = useQuery({
    queryKey: ["failed-events"],
    queryFn: () => eventService.getFailed(),
    refetchInterval: 5000
  });

  const futureEventsQuery = useQuery({
    queryKey: ["future-events"],
    queryFn: () => eventService.getFuture(),
    refetchInterval: 5000
  });

  const handleRetry = (id) => {
    eventService
      .retry(id)
      .then(() => {
        failedEventsQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    eventService
      .delete(id)
      .then(() => {
        failedEventsQuery.refetch();
        futureEventsQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="content">
      <Row xs={2}>
        <Col>
          <div>Failed events</div>
          {{
            loading: () => <div>Loading...</div>,
            error: () => <div>{failedEventsQuery.error.message}</div>,
            success: () => (
              <div>
                {failedEventsQuery.data.length === 0 && (
                  <div className="text-center p-2">No events</div>
                )}
                {failedEventsQuery.data.map((event) => (
                  <EventItem
                    key={event.id}
                    event={event}
                    onRetry={handleRetry}
                    onDelete={handleDelete}
                  />
                ))}
              </div>
            ),
          }[failedEventsQuery.status]()}
        </Col>
        <Col>
          <div>Future events</div>
          {{
            loading: () => <div>Loading...</div>,
            error: () => <div>{futureEventsQuery.error.message}</div>,
            success: () => (
              <div>
                {futureEventsQuery.data.length === 0 && (
                  <div className="text-center p-2">No events</div>
                )}
                {futureEventsQuery.data.map((event) => (
                  <EventItem
                    key={event.id}
                    event={event}
                    onDelete={handleDelete}
                  />
                ))}
              </div>
            ),
          }[futureEventsQuery.status]()}
        </Col>
      </Row>
    </div>
  );
}

export default Events;
