import React from "react";
import {
  Button,
  Card,
  CardBody,
} from "reactstrap";

function ChatUserList({ users, total, onDelete }) {
  return (
    <div>
      <div>
        {users.length === 0 && <div className="text-center p-4">No users</div>}
        {users.map((user) => (
          <Card key={user.telegram_id} className="mb-2">
            <CardBody className="d-flex justify-content-between">
              <div style={{ color: 'white', fontSize: '16px' }}>
                {user.first_name} {user.last_name} {user.username && `(@${user.username})`}{' '}
                <span style={{ color: '#ffffff40' }}>
                  {user.telegram_id}
                </span>
              </div>
              <Button
                className="d-flex align-items-center px-4"
                style={{ gap: "5px" }}
                onClick={() => onDelete(user.telegram_id)}
              >
                <i className="tim-icons tim-trash-simple" />
                <span>Delete</span>
              </Button>
            </CardBody>
          </Card>
        ))}
        {users.length !== total && (
          <div className="text-center">
            showed {users.length} of {total}, use search to get other users
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(ChatUserList);
