/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
// import RTLLayout from "layouts/RTL/RTL.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "assets/css/custom.css"
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import { Provider } from 'react-redux'
import { store } from "store/store";
import { injectStore } from "services/api/apiAxiosInstance";
import { QueryClient, QueryClientProvider } from "react-query";
import ProtectedLayout from "layouts/Protected/Protected";

const root = ReactDOM.createRoot(document.getElementById("root"));

// resolving dependency cycle
injectStore(store);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true
    }
  }
})

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeContextWrapper>
        <BackgroundColorWrapper>
          <BrowserRouter>
            <Routes>
              <Route element={<ProtectedLayout />}>
                <Route path="/admin/*" element={<AdminLayout />} />
                {/* <Route path="/rtl/*" element={<RTLLayout />} /> */}
              </Route>
              <Route
                path="*"
                element={<Navigate to="/admin/chats" replace />}
              />
            </Routes>
          </BrowserRouter>
        </BackgroundColorWrapper>
      </ThemeContextWrapper>
    </QueryClientProvider>
  </Provider>
);
