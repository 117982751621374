/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Advertisements from "views/Advertisements";
import Chats from "views/Chats";
import Events from "views/Events";
// import Dashboard from "views/Dashboard.js";
// import Icons from "views/Icons.js";
// import Map from "views/Map.js";
// import Notifications from "views/Notifications.js";
// import Rtl from "views/Rtl.js";
// import TableList from "views/TableList.js";
// import Typography from "views/Typography.js";
// import UserProfile from "views/UserProfile.js";
import Users from "views/Users";

var routes = [
  {
    path: "/chats",
    name: "Chats",
    icon: "tim-icons icon-chart-pie-36",
    component: <Chats />,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    icon: "tim-icons icon-atom",
    component: <Users />,
    layout: "/admin"
  },
  {
    path: "/advertisements",
    name: "Advertisements",
    icon: "tim-icons icon-atom",
    component: <Advertisements />,
    layout: "/admin"
  },
  {
    path: "/events",
    name: "Events",
    icon: "tim-icons icon-atom",
    component: <Events />,
    layout: "/admin"
  },
];
export default routes;
