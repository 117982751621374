import { useEffect, useState } from "react";

export default function useDebounce(dependValue, delay) {
  const [value, setValue] = useState(dependValue);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue(dependValue);
    }, delay);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [dependValue, delay]);

  return value;
}
