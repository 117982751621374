import ChatForm from "components/ChatForm/ChatForm";
import React from "react";
import { Col, Row } from "reactstrap";

function ChatCreate() {
  return (
    <>
      <div className="content">
        <Row>
          <Col xs={12}>
            <ChatForm />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ChatCreate;
