import React, { useState } from "react";
import advertisementService from "services/api/advertisementService";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import AdvertisementSendModal from "components/AdvertisementSendModal/AdvertisementSendModal";

function Advertisements() {
  const {
    status,
    data: advertisements,
    error,
    refetch,
  } = useQuery({
    queryKey: ["advertisements"],
    queryFn: () => advertisementService.getAll(),
    refetchInterval: 5000,
  });

  const [selectedAd, setSelectedAd] = useState(null);

  const handleCancelSending = (id) => {
    advertisementService
      .cancel(id)
      .then(() => {
        refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSendToAll = (advertisement_id) => {
    advertisementService
      .send(advertisement_id)
      .then(() => {
        refetch()
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const handleDelete = (id) => {
    advertisementService
      .delete(id)
      .then(() => {
        refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="content">
      <h1 className="mb-3">Advertisements</h1>
      <Link to="/admin/advertisements/create">
        <Button className="mb-4">Create</Button>
      </Link>
      <Row xs={1}>
        {{
          loading: () => <div>Loading...</div>,
          error: () => <div>{error.message}</div>,
          success: () => (
            <>
              {advertisements.length === 0 && (
                <div className="p-2 text-center">
                  No advertisements
                </div>
              )}
              {advertisements.map((advertisement) => (
                <Col key={advertisement.id}>
                  <Card>
                    <CardHeader className="d-flex justify-content-between">
                      <div>ID: {advertisement.id}</div>
                      <div style={{ color: "#ffffff50" }} title="status">
                        {advertisement.status}
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div
                        className="mb-3"
                        style={{
                          color: "white",
                          fontSize: "16px",
                        }}
                      >
                        {advertisement.text}
                      </div>
                      {advertisement.files.length > 0 && (
                        <div className="my-3">
                          <div className="text-white">
                            Files:
                          </div>
                          <div>
                            {advertisement.files.map((file) => (
                              <div key={file.path}>
                                {file.path}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {advertisement.next_send_event && (
                        <div
                          className="my-3 rounded"
                          style={{
                            color: "#ffffff50",
                            fontSize: "16px",
                            background: "#ffffff10",
                            padding: "10px",
                          }}>
                          <div>Next send event will be at:</div>
                          <div>
                            {new Date(
                              advertisement.next_send_event.date
                            ).toDateString()}
                          </div>
                          <div>
                            {new Date(
                              advertisement.next_send_event.date
                            ).toTimeString()}
                          </div>
                        </div>
                      )}
                      <div>
                        <Row xs={2} lg={4}>
                          <Col>
                            <Link
                              to={`/admin/advertisements/${advertisement.id}`}
                            >
                              <Button className="w-100">Edit</Button>
                            </Link>
                          </Col>
                            {
                              {
                                IDLE: (
                                  <>
                                    <Col>
                                      <Button
                                        className="w-100"
                                        onClick={() => handleSendToAll(advertisement.id)}
                                      >
                                        Send to all users
                                      </Button>
                                    </Col>

                                    <Col>
                                      <Button
                                        className="w-100"
                                        onClick={() =>
                                          setSelectedAd(advertisement)
                                        }
                                      >
                                        Schedule
                                      </Button>
                                      <AdvertisementSendModal
                                        advertisement={selectedAd}
                                        onSend={() => {
                                          setSelectedAd(null);
                                          refetch();
                                        }}
                                        onClose={() => setSelectedAd(null)}
                                      />
                                    </Col>
                                  </>
                                ),
                                SENDING: (
                                  <Col>
                                    <Button
                                      className="w-100"
                                      color="danger"
                                      onClick={() =>
                                        handleCancelSending(advertisement.id)
                                      }
                                    >
                                      Cancel sending
                                    </Button>
                                  </Col>
                                ),
                              }[advertisement.status]
                            }
                          <Col>
                            <Button
                              className="w-100"
                              color="danger"
                              onClick={() => handleDelete(advertisement.id)}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </>
          ),
        }[status]()}
      </Row>
    </div>
  );
}

export default Advertisements;
