import ChatSelector from "components/ChatSelector/ChatSelector";
import React, { useState } from "react";
import { Button, Card, CardBody } from "reactstrap";

function UserChatList({ chats, onAdd, onDelete }) {
  const [selectedChatId, setSelectedChatId] = useState(null);

  const handleAdd = () => {
    if (selectedChatId) {
      setSelectedChatId(null);
      onAdd(selectedChatId);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-2" style={{ gap: "10px" }}>
        <ChatSelector
          value={selectedChatId}
          setValue={setSelectedChatId}
          excludeIds={chats.map((chat) => chat.id)}
        />
        <Button onClick={handleAdd}>Add</Button>
      </div>
      {chats.length === 0 && <div className="text-center p-4">No chats</div>}
      {chats.map((chat) => (
        <Card key={chat.id}>
          <CardBody className="d-flex align-items-center justify-content-between">
            <div style={{ color: 'white', fontSize: '16px' }}>
              {chat.title} {chat.username && `(@${chat.username})`}{' '}
              <span style={{ color: '#ffffff40' }}>
                {chat.id}
              </span>
            </div>
            <Button
              className="d-flex px-3"
              style={{ gap: "5px" }}
              color="danger"
              onClick={() => onDelete(chat.id)}
            >
              <i className="tim-icons icon-trash-simple" />
              <span>Delete</span>
            </Button>
          </CardBody>
        </Card>
      ))}
    </div>
  );
}

export default React.memo(UserChatList);
